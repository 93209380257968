<template>
    <div class="modal-basic">
        <div v-if="!options.hideHeader" class="head">
            <div class="title" :class="options.titleClass" v-html="$translate(title)" />
        </div>
        <div class="body" :class="{ 'm-t-0': options.hideHeader }" v-html="body" />
        <hr class="hor-divider" />
        <div v-if="buttons" class="buttons-basic">
            <div class="flex-row">
                <button @click="onClickButton(btn)" :key="idx" v-for="(btn, idx) in buttons" :class="btn.class">
                    {{ btn.label | translate }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ModalBasic',
    props: ['options'],
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
        buttons() {
            return (this.options || {}).buttons || []
        },
    },
    methods: {
        onClickButton(btn) {
            this.$emit('close', this.buttons.indexOf(btn))
        },
        onKeydown(event) {
            if (event.key === 'Enter' && this.buttons) {
                const confirmButton = this.buttons[this.buttons.length - 1]
                if (confirmButton) this.onClickButton(confirmButton)
            }
        },
    },
}
</script>
